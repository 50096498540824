@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

/*
  * section
*/
* {
  font-family: "Poppins", sans-serif;
}

html,body{
  overflow-x: hidden;
  height: 100%;
}

body {
  background-color: #EEEEEE;
}

/*
  Home sections
*/
.home__banner {
  background-color: #EEEEEE;
  height: 500px; 
  padding-bottom: 300px;
}

.home__poster {
  background-color: #FFFFFF;
}

.home__clock {
  background-color: #FFFFFF;
  padding-bottom: 40px;
}

.home__chat {
  background-color: #EEEEEE;
  padding: 80px 0;
}

.home__benefit {
  background-color: #EEEEEE;
  padding-bottom: 40px;
}

.home__book {
  background-color: #31363F;
  padding: 20px 0 60px 0;
}

.home__speaker {
  background-color: #EEEEEE;
  padding: 60px 0;
}

.home__testimony {
  padding: 20px 10px;
}

.home__faq {
  padding: 20px 0;
}

.home__closing {

}

/*
  Navbar section
*/
.navigation-bar {
  background-color: #eeeeeed0;
}

.navigation-bar__brand {
  font-weight: 700;
}

.navigation-bar__item {
  font-weight: 500;
  text-decoration: none;
  color: #31363f;
  margin: 20px;
}

.navigation-bar__item-active {
  font-weight: 600;
  text-decoration: underline;
  color: #76ABAE;
  padding: 5px;
  border-radius: 5px;
  margin: 20px;
}

.navigation-bar__brand img {
  max-width: 80px;
  margin-right: 10px;
}

/*
  Banner section
*/
.banner {
  padding: 100px 0px 0 20px;
  border: none;
  text-align: center;
} 

.banner-fixed {
  min-height: 250px;
}

.banner__title {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 800;
  font-size: 80px;
  display: block;
  color: #31363F;
}

.banner__sub {
  font-size: 20px;
}

.registration__button, .ebook__button {
  margin-top: 10px;
  background-color: #FFFA67;
  color: #31363F;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  padding: 10px;
}

.registration__button:disabled, .ebook__button:disabled {
  background-color: #FFFA67;
  color: #31363F;
}

.banner__button:hover, .registration__form-button:hover, .ebook__button:hover {
  background-color: #f5d72a;
  color: #31363F;
}

/*
  Poster section
*/
.home__poster img {
  display: block;
  margin: 30px auto;
  max-height: 900px;
}

/*
  Speaker section
*/

.speaker {
  
}

.speaker__title, .benefit__title, .testimony__title, .clock__title {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  padding-bottom: 40px;
}

.speaker__row {
  width: 80%;
  margin: 0 auto;
}

.speaker__card {
  background-color: #FFFFFF;
  padding: 40px 10px;  
  border-radius: 20px;
}

.speaker__card__title {
  font-size: 25px;
  font-weight: 600;
}

.speaker__card__title-sub {
  font-size: 20px;
  font-weight: 500;
}

.speaker__card__date {
  font-size: 20px;
  color: #587e80;
}

.speaker__card__speaker-name {
  font-size: 18px;
  font-weight: 500;
}

/*
  Registration section
*/
.registration {
  margin: 100px 0 80px 0;
}

.registration__title, .faq__title {
  margin-bottom: 10px;
  font-size: 35px;
  font-weight: 600;
} 

.registration__form-label {
  font-size: 20px;
  font-weight: 600;
}

.registration__form-input, .registration__form-input:focus {
  background-color: #FFFFFF;
  border: none;
  font-size: 18px;
}

.registration__form-check {
  font-size: 18px;
}

.registration__form-required {
  font-size: 2px;
  font-weight: 700;
}

.registration__form-button {
  width: 100%;
  font-weight: 600;
  background-color: #FFFA67;
  color: #31363F;
  border: none;
}

.infaq-payment-section {
  background-color: #FFFFFF;
  margin-bottom: 10px;
  font-size: 14px;
  cursor: pointer;
  height: 100%;
}

.infaq-payment-section img {
  max-width: 60px;
}

.infaq-payment-section__desc {
  margin: 4px 0 0 30px;
  font-weight: 600;
}

.infaq-payment-section__num {
  margin: 4px 0 0 30px;
  font-weight: 400;
}

.infaq-payment-section__copy-icon {
  color: #FFFA67;
}

/*
  Event section
*/
.clock {
  text-align: center;
  width: 65%;
  margin: 0 auto 0 auto;
}

.clock__item {
  margin: 5px;
  background-color: #FFFA67;
  padding: 20px;
  border-radius: 5px;
}

.clock__item-number {
  font-size: 50px;
  font-weight: 700;
}

.clock__item-caps {
  font-weight: 500;
}

.clock__title-sub {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

/*
  Benefit section
*/

.benefit__card {
  background-color: #FFFFFF;
  padding: 10px;
  text-align: center;
  border: none;
  border-radius: 5px;
  height: 100%;
}

.benefit__card-illust {
  font-size: 60px;
}

.benefit__card-desc {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
}

/*
  FAQ
*/
.faq {
  width: 95%;
}

.faq-item {
  border: none;
}

.faq-item__answer {
  background-color: #FFFFFF;
  font-size: 20px;
}

.accordion-button {
  background-color: #FFFA67 !important;
  font-weight: 500;
  font-size: 20px;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #212529;
}

/*
  Chat illustration section
*/
.chat-illust {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  width: 70%;
}

/*
  Book Card Section
*/
.book__title {
  text-align: center;
  color: #FFFFFF;
  font-size: 30px;
  font-weight: 600;
  margin: 20px;
}

.book__desc {
  text-align: center;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 600;
  margin-top: 40px;
}

.book__desc__price {
  text-align: center;
  padding: 20px;
  font-weight: 500;
}

.book__desc__price span {
  background-color: #FFFA67;
  border-radius: 20px;
  padding: 10px;
}

.book__desc__date {
  text-align: center;
  font-weight: 500;
  color: #FFFFFF;
}

.book__card-title {
  font-weight: 600;
}

.book__card-price {
  color: #76ABAE;
  text-decoration: line-through;
}

/*
  Testimony section
*/
.testimony-card {
  background-color: #FFFFFF;
  padding: 10px 30px 50px 30px;
  border-radius: 5px;
  height: 100%;
}

.testimony-card__icon {
  font-size: 80px;
  padding-bottom: 20px;
  color: #76ABAE;
}

.testimony-card__profile {
  margin-top: 30px;
}

.testimony-card__profile img {
  max-width: 70px;
}

.testimony-card__profile-desc {
  margin-left: 40px;
}

.testimony-card__from__name {
  font-weight: 700;
}

.testimony-card__from__sub {
  font-size: 15px;
  font-weight: 500;
}

.testimony-card__content {
  text-align: justify;
  margin-top: 20px;
}

/*
  Closing section
*/

.closing {
  margin: 40px auto;
  padding: 30px;
  width: 80%;
  background-color: #31363F;
  color: #FFFFFF;
  border-radius: 10px;
  text-align: center;
}

.closing__btn {
  margin: 20px 0;
}

.closing__bold {
  font-size: 24px;
  font-weight: 600;
}

/*
  Footer section
*/

.footer {
  background-color: #31363F;
  padding: 30px 10px;
  color: #FFFFFF;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 500;
}

.footer__title, .footer__web {
  margin-left: 20px;
}

.footer__title {
  font-size: 40px;
  font-weight: 600;
}

.footer__web {
  font-size: 25px;
}

.footer__socmed {
  cursor: pointer;
}

.redirect {
  padding: 120px 0 0 10px;
}

.redirect__title, .redirect__btn-area {
  width: 50%;
  padding: 20px;
  margin: 0 auto;
}

.redirect__title {
  text-align: center;
  font-weight: 600;
  font-size: 30px;
}

.redirect__whatsapp-group {
  width: 100%;
  margin: 15px 0;
  background-color: #2cd46b;
  border: none;
  font-weight: 500;
}

.redirect__whatsapp-group:hover {
  background-color: #59ee92;
}

.redirect__whatsapp-group span {
  border-radius: 10px;
  font-weight: 600;
}

.closed-registration-banner {
  margin: 150px 0 150px 0;
  background-color: #31363F;
  color: #ffffff;
  text-align: center;
  padding: 40px;
}

.closed-registration-banner__title {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 10px;
}

.download {
  padding-top: 120px;
}

.download__form {
  width: 70%;
  margin: 0px auto;
}

.download__form-item {
  margin: 10px 0 10px 0;
}

.download__form-title {
  font-weight: 600;
  font-size: 30px;
}

.infaq__card {
  height: 100%;
}

.book__modal {
  background-color: #eeeeee;
}

.btn__download {
  background-color: #2ec53a;
  color: #eeeeee;
  border: none;
}

.btn__download:hover {
  background-color: #587e80;
}

.infaq__broadcast-button {
  background-color: #eee;
  border: none;
  color: #31363f;
  font-weight: 500;
}

.infaq__broadcast-button:hover {
  background-color: #d1d4d4;
  color: #31363F;
}

.ebook__poster {
  text-align: center;
  margin-bottom: 60px;
}

.ebook__poster img {
  max-width: 60%;
}

.ebook-modal__warning {
  background-color: #31363f;
  width: 80%;
  margin: 20px auto;
  color: #ffffff;
  text-align: center;
  padding: 20px;
  border-radius: 5px;
}

.ebook-modal__warning-title {
  font-weight: 600;
  font-size: 30px;
  color: #FFFA67;
}

@media (max-width: 1000px){
  .banner {
    padding: 100px 0px 0 20px;
    border: none;
    text-align: center;
  } 

  .banner-fixed {
    height: 200px;
  }

  .banner__title {
    font-weight: 800;
    font-size: 50px;
    display: block;
  }
  
  .banner__sub {
    font-size: 20px;
  }

  .home__poster img {
    max-height: 550px;
  }

  .chat-illust {
    width: 95%;
  }

  .book-card {
    width: 90%;
  }

  .testimony-card__profile-desc {
    margin-left: 10px;
  }
  
  .clock {
    width: 80%;
  }

  .clock__item {
    padding: 10px;
  }

  .clock__item-number {
    font-size: 20px;
  }

  .speaker__row {
    width: 95%;
    margin: 0 auto;
    text-align: center;
  }
  
  .speaker__avatar {
    align-items: center;
    margin-bottom: 20px;
  }

  .speaker__card img {
    width: 100px;
  }

  .speaker__card__title {
    font-size: 18px;
    font-weight: 600;
  }

  .speaker__card__title-sub {
    font-size: 14px;
    font-weight: 500;
  }

  .footer__socmed {
    padding-left: 20px;
  }

  .redirect__title, .redirect__btn-area {
    width: 100%;
  }  

  .download__form {
    width: 90%;
  }
  
  .ebook__poster {
    margin-bottom: 20px;
  }

  .ebook__poster img {
    max-width: 95%;
  }
  
}